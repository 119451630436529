/**
 * This composable returns helpers based on the current user.
 */
export default function useUser() {
  const { user } = useAuthentication()
  const permissions = usePermissions()

  // Flags
  const isOwner = computed(() => Boolean(user.value?.IsOwner))
  const isSuperhost = computed(() => Boolean(user.value?.SuperHost?.IsActive))
  const isAdmin = computed(() => permissions.value?.includes('ADMINISTRATOR'))

  // Helpers
  const initials = computed(() => {
    if (!user.value) {
      return
    }

    const { FirstName = '', LastName = '' } = { ...user.value }
    return formatNameAsInitials(FirstName, LastName)
  })

  return {
    isOwner,
    isSuperhost,
    isAdmin,

    initials,
  }
}
